export const profileIconData = [
  { seed: "Boo" },
  { seed: "Cuddles" },
  { seed: "Loki" },
  { seed: "Kiki" },
  { seed: "Ginger" },
  { seed: "Lola" },
  { seed: "Bailey" },
  { seed: "Boots" },
  { seed: "Kitty" },
  { seed: "Garfield" },
  { seed: "Shadow" },
  { seed: "Miss kitty" },
  { seed: "Jasper" },
  { seed: "Sam" },
  { seed: "Buster" },
  { seed: "Lucky" },
  { seed: "None" },
  { seed: "Jasmine" },
  { seed: "Missy" },
  { seed: "Nala" },
  { seed: "Cali" },
  { seed: "Smokey" },
  { seed: "Annie" },
  { seed: "Abby" },
  { seed: "Midnight" },
  { seed: "Lucy" },
  { seed: "Leo" },
  { seed: "Tigger" },
  { seed: "Misty" },
  { seed: "Mia" },
  { seed: "Gra" },
  { seed: "Willow" },
  { seed: "Charlie" },
  { seed: "Jasp" },
  { seed: "Gizmo" },
  { seed: "Gracie" },
  { seed: "Spooky" },
  { seed: "Socks" },
  { seed: "Peanut" },
  { seed: "Oliver" },
  { seed: "Salem" },
  { seed: "Whiskers" },
  { seed: "Milo" },
  { seed: "Oreo" },
  { seed: "Simba" },
  { seed: "Coco" },
  { seed: "Rocky" },
  { seed: "Mochi" },
  { seed: "Luna" },
  { seed: "Zeus" },
  { seed: "Tiger" },
  { seed: "Pepper" },
  { seed: "Bella" },
  { seed: "Max" },
  { seed: "Cleo" },
  { seed: "Teddy" },
  { seed: "Mittens" },
  { seed: "Whiskey" },
  { seed: "Noodle" },
  { seed: "Pumpkin" },
  { seed: "Shadow I" },
  { seed: "Lily I" },
  { seed: "Mango I" },
  { seed: "Sunny I" },
  { seed: "Cinnamon I" },
  { seed: "Olivia I" },
  { seed: "Felix I" },
  { seed: "Charlie I" },
  { seed: "Tinkerbell I" },
  { seed: "Apollo I" },
  { seed: "Tinkerbelle I" },
  { seed: "Apollos I" },
  { seed: "Whiskers II" },
  { seed: "Mocha I" },
  { seed: "Leo II" },
  { seed: "Juno I" },
  { seed: "Sasha I" },
  { seed: "Shadow II" },
  { seed: "Lily II" },
  { seed: "Coco II" },
  { seed: "Oliver II" },
  { seed: "Lucy II" },
  { seed: "Max II" },
  { seed: "Bella II" },
  { seed: "Milo II" },
  { seed: "Oreo II" },
  { seed: "Simba II" },
  { seed: "Charlie II" },
  { seed: "Luna II" },
  { seed: "Mango II" },
  { seed: "Sunny II" },
  { seed: "Cleo II" },
  { seed: "Teddy II" },
  { seed: "Mittens II" },
  { seed: "Whiskey II" },
  { seed: "Noodle II" },
  { seed: "Pumpkin II" },
  { seed: "Sasha II" },
  { seed: "Rocky II" },
  { seed: "Mochi II" },
  { seed: "Misty II" },
  { seed: "Juno II" },
  { seed: "Tiger II" },
  { seed: "Pepper II" },
  { seed: "Mothaz I" },
  { seed: "Socks I" },
  { seed: "Peanut II" },
  { seed: "Oliver III" },
  { seed: "Salem II" },
  { seed: "Lilyyy III" },
  { seed: "Zeus II" },
  { seed: "Tinkerbell II" },
  { seed: "Apollo II" },
  { seed: "Tinkerbelle II" },
  { seed: "Apollos I" },
  { seed: "A I" },
];
